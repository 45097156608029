<template>
  <div>
    <Modal title="Tem certeza que deseja cancelar?" :width="600" v-show="showModal('cancelInvoice')">
      <Alert type="warning">
        <span>
          Atenção, ao cancelar uma Fatura alguns processos (se houver) serão executados, certifique-se que está
          cancelando
          a Fatura correta, esta ação é irreversível...
        </span>
      </Alert>
      <div>
        <i class="fa-solid fa-circle-xmark icon-close"></i>
        <span class="title"> Fatura ficará com status de Cancelada</span>
      </div>
      <div>
        <i class="fa-solid fa-circle-xmark icon-close"></i>
        <span class="title"> Pagamentos vinculados serão excluídos</span>
      </div>
      <div>
        <i class="fa-solid fa-triangle-exclamation icon-warning"></i>
        <span class="title"> Produtos da Locação serão liberados para novo Faturamento</span>
      </div>
      <Confirmation :isModal="false" title="Você tem certeza?" type="danger" :confirmed="cancel" />
    </Modal>
  </div>
</template>
<script>
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "CancelInvoice",
  components: {
    Confirmation,
    Alert,
    Modal,
    Button,
  },
  data() {
    return {
      id: "",
      urlCancelAllApi: "/api/v1/billing/invoice/cancel",
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generic", ["selected"]),
  },
  methods: {
    ...mapMutations("generic", [
      "openModal",
      "hideModal",
      "removeLoading",
      "addEvent",
    ]),
    ...mapActions("generic", ["deleteAllApi"]),
    cancel() {
      let params = {
        url: this.urlCancelAllApi,
        selected: [this.id],
      };

      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("cancelInvoice");
          this.addEvent({ name: "canceledInvoice" });
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "cancelInvoice") {
          this.id = event.data.id;
          this.openModal("cancelInvoice");
        }
      },
      deep: true,
    },
  },
};
</script>
<style  scoped>
.icon-close {
  color: red;
}

.icon-warning {
  color: darkorange;
}
</style>